import type ILocalizationState from '~/store/localization/interfaces/ILocalizationState'
import { type ILocalizationLanguage } from '~/store/localization/types/LocalizationTypes'

export const getters = {
  getSelectedLanguage: (state: ILocalizationState) => (): ILocalizationLanguage => {
    return state.selectedLanguage
  },
  getAvailableLanguages: (state: ILocalizationState) => (): ILocalizationLanguage[] => {
    return state.languages
  },
}
